<template>
  <DurationSlideStep
    :slide="slide"
    @update-slide="updateSlide"
  />
  <SlideStep
    :sub-title="$t('components.slides.calendarMicrosoft.slideStep1.subTitle')"
    :title="$t('components.slides.calendarMicrosoft.slideStep1.title')"
  >
    <template #extra>
      <AddSocialAccountButton :socials="['microsoft']" />
    </template>
    <a-select
      v-model:value="socialMicrosoftAccountId"
      :placeholder="$t('components.slides.calendarMicrosoft.slideStep1.socialAccountSelectPlaceholder')"
      style="width: 100%"
    >
      <a-select-opt-group v-if="microsoftAccounts.length > 0">
        <template #label>
          <span>
            <WindowsFilled />
            Microsoft
          </span>
        </template>
        <a-select-option
          v-for="account in microsoftAccounts"
          :key="account.id"
          :value="account.id"
        >
          <div>
            @ {{ account.name }}
          </div>
        </a-select-option>
      </a-select-opt-group>
    </a-select>
  </SlideStep>
  <SlideStep
    :sub-title="$t('components.slides.calendarMicrosoft.slideStep2.subTitle')"
    :title="$t('components.slides.calendarMicrosoft.slideStep2.title')"
  >
    <a-spin :spinning="calendarsLoading">
      <a-checkbox-group
        v-model:value="calendarIds"
        :options="availableCalendarsOptions"
        class="multirow-checkbox-group"
      />
    </a-spin>
  </SlideStep>
  <SlideStep
    :title="$t('components.slides.calendarMicrosoft.slideStep3.title')"
    no-border
  >
    <SlideViewSelect
      v-model:view="view"
      :data="viewData"
    />
    <div>
      <a-typography-text type="secondary">
        {{ $t('components.slides.calendarMicrosoft.availableOnly') }}
      </a-typography-text>
    </div>
  </SlideStep>
</template>

<script>
import { WindowsFilled } from '@ant-design/icons-vue'
import { computed, defineComponent, reactive, ref, toRaw, toRefs, watch, watchEffect } from 'vue'
import SlideStep from '@/components/SlideStep'
import { useStore } from 'vuex'
import { toStandard } from '@/helpers/SocialAccount'
import { error } from '@/utils'
import SlideViewSelect from '@/components/SlideViewSelect'
import dayTypeImage from '@/assets/calendar-day.svg'
import weekTypeImage from '@/assets/calenat-week.svg'
import monthTypeImage from '@/assets/calendar-month.svg'
import AddSocialAccountButton from '@/components/AddSocialAccountButton'
import { useI18n } from 'vue-i18n'
import { MicrosoftCalendarSlide } from '@/helpers/Slides'
import DurationSlideStep from '@/components/DurationSlideStep.vue'

export default defineComponent({
  name: 'MicrosoftCalendarSlide',
  components: {
    DurationSlideStep,
    AddSocialAccountButton,
    SlideViewSelect,
    SlideStep,
    WindowsFilled
  },
  props: {
    title: String,
    slideObj: MicrosoftCalendarSlide,
    groupId: String,
    disabled: Boolean
  },
  emits: ['save', 'update:disabled', 'update-slide', 'init-name', 'close'],
  setup (props, { emit }) {
    const store = useStore()
    const { t } = useI18n()
    const slide = props.slideObj || new MicrosoftCalendarSlide()
    const calendarsLoading = ref(false)
    const {
      view,
      socialMicrosoftAccountId,
      calendarIds
    } = slide.slideData
    const availableCalendars = ref([])
    const state = reactive({
      view,
      socialMicrosoftAccountId,
      calendarIds
    })
    const viewData = computed(() => [
      {
        text: t('components.slides.calendarMicrosoft.day'),
        view: 'DAY',
        image: dayTypeImage
      },
      {
        text: t('components.slides.calendarMicrosoft.week'),
        view: 'WEEK',
        image: weekTypeImage
      },
      {
        text: t('components.slides.calendarMicrosoft.month'),
        view: 'MONTH',
        image: monthTypeImage
      }
    ])

    const availableCalendarsOptions = computed(() => {
      return availableCalendars.value.map(calendar => {
        return {
          label: calendar.name,
          value: calendar.id
        }
      })
    })

    emit('init-name', {
      name: slide.name,
      defaultName: slide.getDefaultName(t)
    })

    const microsoftAccounts = computed(() => store.getters['social/socialMicrosoftAccounts'].map(toStandard))

    const isValid = computed(() => {
      return (state.calendarIds?.length > 0)
    })

    emit('update:disabled', !isValid.value)

    const updateSlideData = () => {
      const {
        view,
        socialMicrosoftAccountId,
        calendarIds
      } = toRaw(state)
      slide.updateSlideData({
        view,
        socialMicrosoftAccountId,
        calendarIds
      })
    }

    const updateSlide = () => {
      emit('update-slide', slide)
    }

    const toggleDisabled = (status) => {
      emit('update:disabled', status)
    }

    const selectView = (view) => {
      state.view = view
    }

    watch(() => state, () => {
      emit('update:disabled', !isValid.value)
      if (isValid.value) {
        updateSlideData()
        updateSlide()
        toggleDisabled(false)
      }
    }, { deep: true })

    watchEffect(() => {
      if (microsoftAccounts.value?.length === 1 ?? !state.socialMicrosoftAccountId) {
        state.socialMicrosoftAccountId = microsoftAccounts.value[0].id
      }
    })

    watchEffect(() => {
      if (state.socialMicrosoftAccountId) {
        calendarsLoading.value = true
        store.dispatch('social/getAvailableMicrosoftCalendarBySocialAccountId', state.socialMicrosoftAccountId).then(({ data: { getAvailableCalendarsByMicrosoftSocialAccountId } }) => {
          availableCalendars.value = getAvailableCalendarsByMicrosoftSocialAccountId
        }).catch(e => {
          error(e.message)
        }).then(() => {
          calendarsLoading.value = false
        })
      }
    })

    watch(() => microsoftAccounts.value, (value, oldValue) => {
      if (value?.length - oldValue?.length === 1) {
        const newMicrosoftId = value?.find(({ id }) => !oldValue.some(({ id: oldId }) => oldId === id))?.id
        if (newMicrosoftId) {
          state.socialMicrosoftAccountId = newMicrosoftId
        }
      }
    })

    return {
      ...toRefs(state),
      slide,
      viewData,
      availableCalendars,
      calendarsLoading,
      availableCalendarsOptions,
      microsoftAccounts,
      selectView,
      updateSlide
    }
  }
})
</script>

<style scoped>

</style>
